<template>
<div class="head_box">
  <div class="sys_head">
    <div class="head_left">
      <div class="system_name">消防物联网监控云平台</div>
    </div>
    <div class="head_middle">
      <div class="flexAC system_tabs" v-if="!isCustomer">
        <div v-for="item in system" :key="item.index" :class="systemTab === item.ac? 'tab_active':''" @click="changeTab(item)">
          {{item.name}}
        </div>
      </div>
      <div class="flexAC system_tabs" v-if="isCustomer">
        <div v-for="item in cusSystem" :key="item.index" :class="systemTab === item.ac? 'tab_active':''" @click="changeTab(item)">
          {{item.name}}
        </div>
      </div>
    </div>
    <div class="head_right flexCE">
      <!-- <div class="daiban flexAC"><span class="iconfont iconmto"></span><span>代办中心</span></div> -->
      <!--<div class="hidden_panel flexAC"><span class="iconfont iconmtt"></span><span>隐藏看板</span></div>-->
      <el-dropdown>
        <div class="hr_item">
          <span class="iconfont iconmtu"></span>
          <span class="hr_itName">{{username}}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div @click="change"><span>修改密码</span> </div>
          </el-dropdown-item>
          <el-dropdown-item v-if="isCustomer">
            <div @click="noticeHand"><span>通知设置</span> </div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div @click="APIChange"><span>公共API</span> </div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div @click="loginOut"><span>退出登录</span> </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
  <!-- 修改密码 -->
  <el-dialog title="修改密码" :visible.sync="showPwd" width="600px" center class="pawdDialog hdBg hdTb" :close-on-click-modal="false" :before-close="pwdCancel">
    <el-form :model="pwdfrom" :rules="formRules" ref="pwdFrom">
      <el-form-item label="旧密码"  prop="password">
        <el-input type="password" v-model="pwdfrom.password"  placeholder="请输入旧密码"></el-input>
      </el-form-item>
      <el-form-item label="新密码"  prop="now_password">
        <el-input type="password" v-model="pwdfrom.now_password"  placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码"  prop="now_password2">
        <el-input type="password" v-model="pwdfrom.now_password2"  placeholder="请再次输入新密码"></el-input>
      </el-form-item>
    </el-form>
    <div class="flexCE">
      <div class="handbtn" @click="pwdCancel">取消</div>
      <div class="handbtn" @click="pwdFromConfirm">确定</div>
    </div>
  </el-dialog>

  <!-- 通知设置 -->
  <el-dialog title="通知设置" class="hdBg hdTb" :visible.sync="showSet" width="910px" center :close-on-click-modal="false" :before-close="setCancel">
    <el-form :model="setfrom" :rules="setformRules" ref="setFrom">
      <div class="flexAC" style="margin-bottom: 10px;">
        <div class="udi_k"></div><p class="udi_t" style="color: #fff;">告警弹窗</p>
      </div>
      <el-form-item label="" class="foItem kgri">
        <el-radio-group v-model="setfrom.homepage" style="width:550px;">
          <el-radio label="1">开启</el-radio>
          <el-radio label="2">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="ud_left">
        <div class="flexAC" style="margin-bottom: 10px;">
          <div class="udi_k"></div><p class="udi_t" style="color: #fff;">告警通知</p>
        </div>
        <div class="flexCB">
          <el-form-item label="火警信息" class="noteItem">
            <el-checkbox  v-model="setfrom.firealarm.rat" true-label="1" false-label="2" >平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.firealarm.sms" true-label="1" false-label="2" >短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.firealarm.voe" true-label="1" false-label="2" >语音通知</el-checkbox>
          </el-form-item>
          <el-form-item label="故障" class="noteItem">
            <el-checkbox  v-model="setfrom.faultalarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.faultalarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.faultalarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="隐患" class="noteItem">
            <el-checkbox  v-model="setfrom.hiddalarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.hiddalarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.hiddalarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
          </el-form-item>
          <el-form-item label="其他" class="noteItem">
            <el-checkbox  v-model="setfrom.otheralarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.otheralarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.otheralarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
          </el-form-item>
        </div>
      </div>
      <div class="ud_left">
        <div class="flexAC" style="margin-bottom: 10px;">
          <div class="udi_k"></div><p class="udi_t" style="color: #fff;">其他通知</p>
        </div>
        <div class="flexCB">
          <el-form-item label="巡检通知" class="noteItem">
            <el-checkbox  v-model="setfrom.inspealarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.inspealarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.inspealarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
          </el-form-item>
          <el-form-item label="逾期通知" class="noteItem">
            <el-checkbox  v-model="setfrom.withialarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.withialarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.withialarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
          </el-form-item>
        </div>
        <el-form-item label="告警通知" class="noteItem">
          <el-checkbox  v-model="setfrom.declalarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
          <el-checkbox  v-model="setfrom.declalarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
          <el-checkbox  v-model="setfrom.declalarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
        </el-form-item>
      </div>
    </el-form>
    <div class="flexCE">
      <div class="afCancel" @click="setCancel">取消</div>
      <div class="afConfirm" @click="setFromConfirm">确定</div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { userPwdChange } from '@/http/api/user'
import { getnoticeSet, noticeSetup } from '@/http/api/settingSystem'
const SM3 = require('sm-crypto')
export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入新密码'))
      } else if (value.toString().length < 6 || value.toString().length > 18) {
        callback(new Error('密码长度为6-18位'))
      } else if (value === this.pwdfrom.password) {
        callback(new Error('新密码不能与原密码相同!'))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.pwdfrom.now_password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      system: [
        { name: '监控中心', path: 'home', ac: 'home' },
        { name: '单位概况', path: 'work', ac: 'work' },
        { name: '数据分析', path: 'dataPanel', ac: 'dataPanel' },
        { name: '设置中心', path: '/setting', ac: null }
      ],
      cusSystem: [
        { name: '监控中心', path: 'customerCenter', ac: 'customerCenter' },
        { name: '联网单位', path: 'connect', ac: 'connect' },
        { name: '数据分析', path: 'dataPanel', ac: 'dataPanel' },
        { name: '隐患管理', path: 'hidden', ac: 'hidden' },
        { name: '业务处理', path: 'business', ac: 'business' }
      ],
      systemTab: '',
      tabActive: '',
      showPwd: false,
      pwdfrom: {
        password: '',
        now_password: '',
        now_password2: ''
      },
      formRules: {
        password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        now_password: [{ required: true, validator: validatePass, trigger: 'blur' }],
        now_password2: [{ required: true, validator: validatePass2, trigger: 'blur' }]
      },
      username: '',
      roleid: '',
      usermsgid: '',
      isCustomer: false,
      showSet: false,
      setfrom: {
        homepage: '1',
        firealarm: { rat: '0', sms: '0', voe: '0' },
        faultalarm: { rat: '0', sms: '0', voe: '0' },
        hiddalarm: { rat: '0', sms: '0', voe: '0' },
        otheralarm: { rat: '0', sms: '0', voe: '0' },
        inspealarm: { rat: '0', sms: '0', voe: '0' },
        withialarm: { rat: '0', sms: '0', voe: '0' },
        declalarm: { rat: '0', sms: '0', voe: '0' }
      },
      setformRules: {}
    }
  },
  watch: {
    $route (to, from) {
      this.systemTab = JSON.parse(window.localStorage.getItem('tabActive')).ac
    }
  },
  mounted () {
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.isCustomer = true
    } else {
      this.isCustomer = false
    }
    this.tabActive = JSON.parse(window.localStorage.getItem('tabActive')).ac
    this.userid = JSON.parse(window.localStorage.getItem('loginInfo')).userid
    this.usermsgid = JSON.parse(window.localStorage.getItem('loginInfo')).usermsgid
    this.username = JSON.parse(window.localStorage.getItem('loginInfo')).username
    this.systemTab = this.tabActive
  },
  methods: {
    pwdCancel () {
      this.showPwd = false
    },
    // 修改密码提交
    pwdFromConfirm () {
      this.$refs.pwdFrom.validate((valid) => {
        if (!valid) return
        const password = SM3.sm3(this.pwdfrom.password)
        const nowPassword = SM3.sm3(this.pwdfrom.now_password)
        const params = {
          userid: this.userid,
          password: password,
          now_password: nowPassword
        }
        userPwdChange(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            setTimeout(() => {
              this.loginOut()
            }, 1000)
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    change () {
      this.$emit('changeTheme')
      this.showPwd = true
    },
    changeTab (item) {
      this.$router.push(item.path)
      if (item.ac !== null) {
        this.systemTab = item.ac
        const obj = { path: item.path, ac: item.ac }
        window.localStorage.setItem('tabActive', JSON.stringify(obj))
      }
    },
    /* 通知设置 */
    noticeHand () {
      this.showSet = true
      getnoticeSet({ usermsgid: this.usermsgid }).then(res => {
        if (res.status === '1') {
          this.setfrom.homepage = res.data.homepage
          this.setfrom.firealarm = res.data.firealarm
          this.setfrom.declalarm = res.data.declalarm
          this.setfrom.faultalarm = res.data.faultalarm
          this.setfrom.hiddalarm = res.data.hiddalarm
          this.setfrom.inspealarm = res.data.inspealarm
          this.setfrom.otheralarm = res.data.otheralarm
          this.setfrom.withialarm = res.data.withialarm
        } else {
          this.setfrom = {}
        }
      })
    },
    setCancel () {
      this.showSet = false
    },
    /* 通知提交 */
    setFromConfirm () {
      this.$refs.setFrom.validate((valid) => {
        if (!valid) return
        const params = {
          usermsgid: this.usermsgid,
          homepage: this.setfrom.homepage,
          firealarm: this.setfrom.firealarm,
          faultalarm: this.setfrom.faultalarm,
          hiddalarm: this.setfrom.hiddalarm,
          otheralarm: this.setfrom.otheralarm,
          inspealarm: this.setfrom.inspealarm,
          withialarm: this.setfrom.withialarm,
          declalarm: this.setfrom.declalarm
        }
        noticeSetup(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.setCancel()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 退出登陆
    loginOut () {
      this.$router.push('/Login')
      window.localStorage.setItem('loginInfo', '')
      window.localStorage.setItem('token', '')
      window.localStorage.setItem('tabActive', '')
      window.sessionStorage.setItem('LoginState', '0')
      this.$store.commit('setLoginState', !this.$store.getters.loginState)
    },
    // 公共API
    APIChange() {
      window.open(this.BASE_URL + this.$url.PATH.handAPI, '_blank')
    }
  }
}
</script>

<style scoped>
.sys_head{
  width: 100%;
  height: 70px;
  line-height: 70px;
  padding: 0 40px;
  box-sizing: border-box;
  z-index: 999;
  position: absolute;
  display: flex;
  align-items: center;
  background: var(--mainHeadBackground);
  background-size: 100% 110%;
}
.head_left{
  width: 380px;
  display: flex;
  align-content: center;
}
.system_logo,.system_name{
  line-height: 100%;
}
.system_name{
  height: 40px;
  font-size: 28px;
  line-height: 40px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
  opacity: 1;
}
.head_middle{
  /*width: 52%;*/
  width: calc(100% - 630px);
}
.head_right{
  width: 250px;
  color: #88ACFC;
  font-size: 14px;
}
.hidden_panel{
  margin: 0 40px;
}
.daiban,.hidden_panel,.hr_item{
  cursor: pointer;
}
.daiban .iconfont,.hidden_panel .iconfont,.hr_item .iconfont{
  font-size: 20px;
}
.hr_item{
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #88ACFC;
}
.system_tabs{
  color: #fff;
  height: 40px;
}
.system_tabs div{
  margin-right: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  padding: 0px 10px;
  box-sizing: border-box;
  font-size: 17px;
}
.system_tabs .tab_active{
  color: #FFAD42;
  background: url("../assets/img/tab_active.png") no-repeat;
  background-size: 100% 100%;
}
.handbtn{
  width: 90px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin: 0px 30px;
  background: rgba(136, 172, 252, 0.16);
  border-radius: 4px;
  color: #FFAD42;
  cursor: pointer;
}
.pawdDialog >>> .el-form-item__label{
  color: #FFFFFF;
}
.pawdDialog >>> .el-input__inner{
  background: rgba(136, 172, 252, 0.16);
  color: #A5B7CC;
}
.noteItem >>> .el-form-item__label{
  color: #eee;
}
.noteItem >>> .el-checkbox__input.is-checked+.el-checkbox__label{
  color: #ffad42;
}
.noteItem >>> .el-checkbox__input.is-checked .el-checkbox__inner, .noteItem >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #ffad42;
  border-color: #ffad42;
}
.noteItem >>> .el-checkbox__inner{
  background-color: #606266;
}
.kgri >>> .el-radio__input.is-checked .el-radio__inner{
  border-color: #ffad42;
  background: #ffad42;
}
.kgri >>> .el-radio__input.is-checked+.el-radio__label{
  color: #ffad42;
}
.kgri >>> .el-radio__inner{
  background-color: #606266;
}
</style>
