<template>
  <div></div>
</template>

<script>
import Stomp from 'stompjs'
import { msgLogin } from '@/http/api/home'
export default {
  name: 'rabbit',
  data () {
    return {
      client: null,
      state: '',
      ginState: ''
    }
  },
  computed: {
    loginState () {
      return this.$store.getters.loginState
    }
  },
  watch: {
    loginState (n, m) {
      var state = window.sessionStorage.getItem('LoginState')
      if (state === '1') {
        this.connect()
      } else {
        this.close()
      }
    }
  },
  created () {
    this.state = window.sessionStorage.getItem('LoginState')
    if (this.state === '1') {
      this.connect()
    } else {
      this.close()
    }
  },
  methods: {
    connect () {
      console.log('开始链接')
      const ws = new WebSocket(this.$url.MQTT.SERVICE)
      this.client = Stomp.over(ws)
      const headers = {
        login: this.$url.MQTT.USERNAME,
        passcode: this.$url.MQTT.PASSWORD,
        host: '/',
        ack: 'client'
      }
      this.client.connect(headers, this.onConnected, this.onFailed)
    },
    onConnected: function () {
      const topicMain = JSON.parse(window.localStorage.getItem('loginInfo')).usermsgid
      const topic = JSON.parse(window.localStorage.getItem('loginInfo')).rabbit
      // const exchange = this.$url.MQTT.EXCHANGE + this.$url.MQTT.TOPIC + topicMain
      const exchange = this.$url.MQTT.EXCHANGE + '/' + topic + '/' + topicMain
      this.getLoginMessage()
      // 消息接收
      this.client.subscribe(exchange, this.responseCallback, { ack: 'client' })
    },
    responseCallback: function (frame) {
      frame.ack()
      var message = JSON.parse(frame.body)
      this.$emit('message', message)
    },
    onFailed: function () {
      console.log('连接失败')
      this.connect()
    },
    close: function () {
      let _this = this
      if (_this.client !== null) {
        _this.client.disconnect(function () {
          console.log('已断开链接')
          _this.client = null
          return false
        })
      }
    },
    getLoginMessage () {
      msgLogin().then(res => {})
    }
  },
  destroyed () {
    this.state = window.sessionStorage.getItem('LoginState')
    if (this.state === '1') {
      this.connect()
    } else {
      this.close()
    }
  }
}
</script>
