<template>
  <div style="height:100%;width:100%;">
    <Header @changeTheme="changeTheme"></Header>
    <div class="contains">
      <!--    <transition :name="transitionName">-->
      <keep-alive include="customerCenter">
        <router-view :messageInfo="messageInfo"></router-view>
      </keep-alive>
      <!--    </transition>-->
    </div>
    <Rabbit @message="message"></Rabbit>

    <!-- 告警弹框 -->
    <div class="alarm_Mask" v-if="alarmShow">
      <!--    ksbuer-->
      <div class="flexSC" :class="alarmContent.ksbuer === true? 'alarm_DialogTwo':'alarm_Dialog'">
        <div class="alarm_cont">
          <div class="ac_top" :class="{actopBorder:alarmContent.ksbuer===true, hiddenDanger:hayClass===true,giveAnAlarm:hayClass===false}">
            <p class="iconfont iconclose acTopIco" @click.stop="handClose"></p>
            <div class="flexCB"  >
              <div class="flexAC">
                <span class="iconfont iconunit" style="font-size: 20px;"></span>
                <span>{{ alarmContent.companyname }}</span>
              </div>
              <div class="ait_r">{{ alarmContent.reportime }}</div>
            </div>
            <audio controls="controls" :src="sound" ref="audio" autoplay="autoplay" hidden="true"></audio>
<!--            <audio v-if="alarmContent.equipstatus == '1'" controls="controls" :src="sound" ref="audio" autoplay="autoplay" hidden="true"></audio>-->
<!--            <audio v-if="alarmContent.equipstatus != '1'" controls="controls" :src="otherMp" ref="audio" autoplay="autoplay" hidden="true"></audio>-->
            <div class="flexCB" style="margin-top: 10px;">
              <div class="acTopL">
                <p><span>设备类型: </span>{{ alarmContent.equname }}</p>
                <p><span>告警内容: </span>{{ alarmContent.content }}</p>
                <p><span>安装位置: </span>{{ alarmContent.equadderss }}</p>
                <p><span>消控室电话: </span>{{ alarmContent.companyphone }}</p>
                <p><span>安全负责人/电话: </span>{{ alarmContent.securityadmin }}/{{ alarmContent.securityphone }}</p>
              </div>
              <div class="acEquIco">
                <span class="iconfont" :class="'icon'+alarmContent.equtypesvg"></span>
              </div>
            </div>
          </div>
<!--          <div class="ac_bot">-->
<!--            <div class="alfi2" @click.stop="handDeal">处理</div>-->
<!--            <div class="alfi2" @click.stop="handAssign">分派</div>-->
<!--            <div class="alfi2" @click.stop="handMisreport">误报</div>-->
<!--          </div>-->
          <div class="ac_bot" @click="handShowDetail"><p class="seeDe">查看详情>></p></div>
        </div>
        <div class="Intercom_box" v-if="alarmContent.ksbuer" @click="showIntercom">
          <div>
            <p class="iconfont iconequf" style="font-size: 32px;"></p>
            <p class="ibTitle">消控室可视对讲</p>
            <p class="iconfont iconzu207" style="font-size: 18px;"></p>
          </div>
        </div>
      </div>
    </div>

    <!--  告警详情弹框  -->
    <el-dialog :visible.sync="alarmDetailShow" class="alarmDetailDialog alarmDialog hdBg" width="1200px" :close-on-click-modal="false">
      <AlarmDetail :alarmKey="alarmKey"
                   @dealHandShow="dealHandShow"
                   @assignHandShow="assignHandShow"
                   @superviseHandShow="superviseHandShow"
                   @misreportHandShow="misreportHandShow"
                   @openImg="openImg"
                   @customerDealShow="customerDealShow"
                   @customerTel="customerTel"
                   @busGzDeal="busGzDeal"
                   @ysDealShow="ysDealShow">
      </AlarmDetail>
    </el-dialog>

    <!-- 告警详情 弹窗 AI放大图片 -->
    <el-dialog title="抓拍图片" :visible.sync="showBigImg" center width="1000px" class="hdBg hdTb" style="z-index: 999;">
      <div class="bigImgBox">
        <div class="sizeBox" @mousewheel.prevent="rollImg">
          <img :src="bigImgUrl" alt="" class="img" ref="imgDiv" :onerror="notImg" @mousedown="ImgMove"/>
        </div>
      </div>
    </el-dialog>

    <!-- 处理 -->
    <el-dialog title="处理" :visible.sync="dealWithShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <DealWith :alarmOperate="alarmOperate"
                @dealHandCancel="dealHandCancel"
                @dealHandConfirm="dealHandConfirm">
      </DealWith>
    </el-dialog>

    <!-- 分派 -->
    <el-dialog title="分派" :visible.sync="assignShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Assign :alarmOperate="alarmOperate"
              @assignHandCancel="assignHandCancel"
              @assignHandConfirm="assignHandConfirm">
      </Assign>
    </el-dialog>

    <!-- 督办 -->
    <el-dialog title="督办" :visible.sync="superviseShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Supervise :alarmOperate="alarmOperate"
                 @superHandCancel="superHandCancel"
                 @superHandConfirm="superHandConfirm">
      </Supervise>
    </el-dialog>

    <!--  客服-处理-弹框  -->
    <el-dialog title="处理" :visible.sync="customerDealWith" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <CusDealWith :alarmInfo="alarmInfo" @dealClose="dealClose" @busDealSuccess="busDealSuccess"></CusDealWith>
    </el-dialog>

    <!-- 客服-延时处理-弹框 -->
    <el-dialog title="延时处理" :visible.sync="ysDealDialog" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Delayed :alarmInfo="alarmInfo" @delayedClose="delayedClose" @delayedSuccess="delayedSuccess"></Delayed>
    </el-dialog>

    <!-- 可视对讲   -->
    <el-dialog title="可视对讲" :visible.sync="cloudShow" width="1300px" center class="hdTb hdBg" :close-on-click-modal="false" :before-close="videoCancel">
      <div class="cloud_container flexSC">
        <div class="cloud_video">
          <div id="PlayerBox" ref="PlayerBox" style="width:100%;height:100%;"></div>
        </div>
        <div class="cloud_info">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div><p class="ud_t" style="margin-left: 3px;">设备基本信息</p>
          </div>
          <p class="ud_c">单位名称: {{ cloudDetail.companyname }}</p>
          <p class="ud_c">设备名称: {{ cloudDetail.equname }}</p>
          <p class="ud_c">设备编号: {{ cloudDetail.equimei }}</p>
          <p class="ud_c">安装位置: {{ cloudDetail.adderss }}</p>
          <p class="ud_c">安全管理人: {{ cloudDetail.username }}</p>
          <p class="ud_c">电话: {{ cloudDetail.userphone }}</p>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import sounds from '@/assets/alarm.mp3'
import other from '@/assets/other.mp3'
import Header from '@/components/header'
import Rabbit from '@/components/rabbit'
import { msgRead } from '@/http/api/home'
import { alarmReport } from '@/http/api/alarm'
import { getEquipList, equCurrent } from '@/http/api/equip'
import AlarmDetail from '@/components/home/alarmDetail'
import DealWith from '@/components/home/dealWith'
import Assign from '@/components/home/assign'
import Supervise from '@/components/home/supervise'
import CusDealWith from '@/components/customer/dealWith.vue'
import Delayed from '@/components/customer/delayed.vue'
import Recorderx from 'recorderx'
import EZUIKit from 'ezuikit-js'
import $ from 'jquery'
import { refresh } from '@/http/api/user'
const rc = new Recorderx()
export default {
  components: {
    Header,
    Rabbit,
    AlarmDetail, // 告警详情
    DealWith, // 处理
    Assign, // 分派
    Supervise, // 督办
    CusDealWith, // 客服处理
    Delayed // 客服延时处理
  },
  data () {
    return {
      sound: sounds,
      otherMp: other,
      isLogin: false,
      transitionName: '',
      messageInfo: '',
      img: require('../assets/img/alarm.png'),
      alarmShow: false,
      alarmDetailShow: false,
      alarmKey: '',
      alarmContent: '',
      // alarmContent: {
      //   companyid: 'ad6ccf0ac4cd11eb8eac0242ac12000b',
      //   companyname: '陕西大智慧（渭南）',
      //   companyphone: '18822220000',
      //   content: '烟雾告警',
      //   ectpid: 'YH2111241849550068198',
      //   equadderss: '沙苑排球馆办公区二层2层北大厅应急配电箱',
      //   equid: '280351d4defb11eb87ba0242ac12000b',
      //   equname: '无线智能烟感',
      //   equtypesvg: 'equa',
      //   ksbuer: true,
      //   ksvideo: '',
      //   latitude: '34.506453',
      //   longitude: '109.453294',
      //   msgid: '12e044d4ff2e11eb8a93244bfe7a0fff',
      //   msgtype: '3',
      //   msgtypename: '设备告警',
      //   receiveuser: 'f0d165b2def911eb91ff0242ac12000b',
      //   reportime: '2021-01-29 10:07:40',
      //   securityadmin: '郭江',
      //   securityphone: '13891308667',
      //   sendname: '超级管理员',
      //   senduser: '0f0e4adaa7f511eba71f244bfe7a0fff',
      //   title: '火警信息',
      //   username: '郭江',
      //   equipstatus: '3',
      //   ishrisk: true
      // },
      alarmOperate: '',
      dealWithShow: false,
      assignShow: false,
      superviseShow: false,
      cloudShow: false,
      cloudDetail: {},
      player: null,
      timer: '',
      showBigImg: false,
      notImg:  'this.src="'+ require('../assets/img/imgError.png') +'"',
      bigImgUrl: '',
      customerDealWith: false,
      alarmInfo: '',
      ysDealDialog: false,
      hayClass: false
    }
  },
  mounted () {
    require('../assets/css/skin/theme.css')
    window.URL = window.URL || window.webkitURL
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia

    // 定时器
    var that = this
    this.timer = setInterval(() => {
      that.timeInit()
    }, 180000)
  },
  methods: {
    timeInit () {
      refresh().then(res => {})
    },
    changeTheme () {
      require('../assets/css/skin/themeOne.css')
    },
    message (res) {
      this.getIsRead(res)
      this.openDanger(this.img, res)
      if (res.msgtype === '3') {
        this.messageInfo = res // 老版本告警

        /* new全部页面告警 */
        this.alarmContent = res
        if (res.equipstatus === '1') {
          this.showAlarmTip()
          this.hayClass = false
        } else if (res.equipstatus === '3') {
          if (res.ishrisk === true) {
            this.showAlarmTip()
            this.hayClass = true
          }
        }
      }
      console.log('火警and隐患' + this.hayClass)
      this.$store.commit('setMsgState', this.$store.getters.msgState)
    },
    showAlarmTip () {
      this.alarmShow = true
      this.alarmDetailShow = false
    },
    // 确定消息已接收
    getIsRead (msg) {
      msgRead({ msgid: msg.msgid }).then(res => {})
    },
    // 消息提示
    openDanger (img, res) {
      this.$notify({
        title: '',
        dangerouslyUseHTMLString: true,
        message: '<div class="message_dialog">' +
          '<p class="msg_title"><img src="' + img + '" /><span>' + res.msgtypename + '</span></p>' +
          '<p class="msg_cont">' + res.content + '</p>' +
          '<p class="msg_time">' + res.reportime + '</p>' +
          '</div>',
        position: 'bottom-right'
      })
    },
    handDeal () {
      this.dealHandShow(this.alarmContent)
    },
    handAssign () {
      this.assignHandShow(this.alarmContent)
    },
    handMisreport () {
      this.misreportHandShow(this.alarmContent)
    },
    /* 关闭告警弹框 */
    handClose () {
      this.alarmShow = false
      this.$refs.audio.pause()
    },
    /* 告警详情展示 */
    handShowDetail () {
      this.alarmDetailShow = true
      this.alarmKey = this.alarmContent
      this.handClose()
    },
    /* 处理展示 */
    dealHandShow (res) {
      this.dealWithShow = true
      this.alarmOperate = res
      // this.handClose()
    },
    // 处理关闭
    dealHandCancel () {
      this.dealWithShow = false
    },
    // 处理提交
    dealHandConfirm () {
      this.dealWithShow = false
      this.alarmDetailShow = false
    },
    /* 分派展示 */
    assignHandShow (res) {
      this.assignShow = true
      this.alarmOperate = res
      // this.handClose()
    },
    // 分派关闭
    assignHandCancel () {
      this.assignShow = false
    },
    // 分派提交
    assignHandConfirm () {
      this.assignShow = false
      this.alarmDetailShow = false
    },
    /* 督办展示 */
    superviseHandShow (res) {
      this.superviseShow = true
      this.alarmOperate = res
      // this.handClose()
    },
    // 督办关闭
    superHandCancel () {
      this.superviseShow = false
    },
    // 督办提交
    superHandConfirm () {
      this.superviseShow = false
      this.alarmDetailShow = false
    },
    /* 客服-处理-(火警-隐患)-展示 */
    customerDealShow (res) {
      this.customerDealWith = true
      this.alarmInfo = res
    },
    /* 客服-处理-(火警-隐患)-取消 */
    dealClose () {
      this.customerDealWith = false
    },
    /* 客服-处理-(火警-隐患)-提交 */
    busDealSuccess () {
      this.customerDealWith = false
      this.alarmDetailShow = false
    },
    /* 客服-电核 */
    customerTel () {
      this.alarmDetailShow = false
    },
    /* 客服-处理-(故障) */
    busGzDeal () {
      this.alarmDetailShow = false
    },
    /* 客服-延时处理-(隐患)-展示 */
    ysDealShow (res) {
      this.ysDealDialog = true
      this.alarmInfo = res
    },
    /* 客服-延时处理-(隐患)-取消*/
    delayedClose () {
      this.ysDealDialog = false
    },
    /* 客服-延时处理-(隐患)-提交*/
    delayedSuccess () {
      this.ysDealDialog = false
      this.alarmDetailShow = false
    },
    // 误报
    misreportHandShow (res) {
      this.alarmDetailShow = false
      // this.$confirm('点击误报后本条告警信息将会自动标记为误报信息，请确定是否误报?', '误报', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   customClass: 'Misinform',
      //   center: true
      // }).then(() => {
      //   alarmReport({ ectpid: res.ectpid }).then(res => {
      //     if (res.status === '1') {
      //       this.$message.success(res.message)
      //       this.handClose()
      //     } else {
      //       this.$message.success(res.message)
      //     }
      //   })
      // }).catch(() => {})
    },
    // 可视对讲展示
    showIntercom () {
      const params = {
        equtype: '2',
        equid: this.alarmContent.ksvideo
      }
      getEquipList(params).then(res => {
        if (res.status === '1') {
          this.cloudShow = true
          this.handClose()
          this.getMicrophone() // 获取麦克风权限
          this.cloudDetail = res.data.data[0]
          // console.log(this.cloudDetail)
          this.$nextTick(() => {
            this.videoPlayer()
          })
        }
      })
    },
    getMicrophone () {
      rc.start().then(() => {
        console.log('获取麦克风成功')
      }).catch(error => {
        console.log('失败原因' + error)
      })
    },
    videoPlayer () {
      equCurrent({ equid: this.cloudDetail.equid }).then(res => {
        if (res.status === '1') {
          this.player = new EZUIKit.EZUIKitPlayer({
            autoplay: true,
            id: 'PlayerBox',
            accessToken: res.data.video_token,
            url: res.data.equip_port,
            template: 'voice', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
            header: ['capturePicture', 'save', 'zoom'], // 如果templete参数不为simple,该字段将被覆盖
            footer: ['talk', 'hd', 'fullScreen'], // 如果template参数不为simple,该字段将被覆盖
            plugin: ['talk'],
            // audio: 1,
            height: 550
          })
        }
      })
    },
    // 可视对讲关闭
    videoCancel () {
      $('#PlayerBox').empty()
      this.cloudShow = false
      this.player = null
    },
    /* Ai摄像 */
    openImg (res) {
      this.showBigImg = true
      this.bigImgUrl = res
      this.$nextTick(() => {
        this.$refs.imgDiv.style.left = '0px'
        this.$refs.imgDiv.style.top = '0px'
        this.$refs.imgDiv.style.zoom = "100%"
      })
    },
    rollImg (event) {
      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100
      zoom += event.wheelDelta / 12
      if (zoom >= 80 && zoom <500) {
        this.$refs.imgDiv.style.zoom = zoom + "%"
      }
      return false
    },
    ImgMove (e) {
      e.preventDefault()
      var left = document.querySelector('.sizeBox')
      var img = document.querySelector('.img')
      var x = e.pageX - img.offsetLeft
      var y = e.pageY - img.offsetTop
      left.addEventListener('mousemove', move)
      function move(e) {
        img.style.left = e.pageX - x + 'px'
        img.style.top = e.pageY - y + 'px'
      }
      img.addEventListener('mouseup', function() {
        left.removeEventListener('mousemove', move)
      })
      left.addEventListener('mouseout', function() {
        left.removeEventListener('mousemove', move)
      })
    }
  },
  destroyed () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
.contains{
  width: 100%;
  height: 100%;
  /*padding-top: 70px;*/
  box-sizing: border-box;
  background-size: 100% 100%;
  color: #fff;
}
.alarm_Mask{
  width: 100%;
  height: 100%;
  background:rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999 !important;
}
.alarm_Dialog{
  width: 460px;
  height: 240px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -220px;
  margin-top: -120px;
}
.alarm_DialogTwo{
  width: 500px;
  height: 260px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -240px;
  margin-top: -120px;
}
.alarm_cont{
  width: 460px;
  height: 100%;
  position: relative;
}
.Intercom_box{
  width: 40px;
  height: 220px;
  background: rgba(9, 18, 35, 1);
  border-radius: 0px 8px 8px 0px;
  color: #FFAD42;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ibTitle{
  width: 40px;
  line-height: 40px;
  font-size: 14px;
  /*margin: 5px 0px;*/
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  letter-spacing: 1px;
}
.ac_top{
  width: 100%;
  height: 220px;
  position: relative;

  /*box-shadow: 0px 0px 50px #840B0B;*/
  border-radius: 8px 8px 0px 0px;
  padding: 25px 25px 20px 20px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
}
.giveAnAlarm{
  background: linear-gradient(135deg, rgba(226, 45, 45, 0.95) 0%, rgba(227, 94, 59, 0.9) 100%);
}
.hiddenDanger{
  background: linear-gradient(315deg, rgba(203, 74, 228, 0.95) 0%, rgba(158, 42, 223, 0.9) 100%);
}
.actopBorder{
  border-radius: 8px 0px 0px 0px;
}
.ac_bot{
  width: 100%;
  height: 40px;
  position: relative;
  background: rgba(9, 18, 35, 1);
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: center;
}
.seeDe{
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: #FFAD42;
  font-size: 14px;
}
.acTopIco{
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
}
.acTopL{
  width: calc(100% - 95px);
}
.acTopL p{
  margin: 5px 0px;
}
.acTopL p span{
  color: #F1ADAB;
}
.acEquIco{
  width: 95px;
  height: 95px;
  background: url("./../assets/img/alarm_ico.png");
  background-size: 100% 100%;
  text-align: center;
  padding-top: 20px;
  box-sizing: border-box;
}
.acEquIco .iconfont{
  font-size: 55px;
}
.alfi2{
  width: 33.3%;
  height: 20px;
  text-align: center;
  color: #FFAD42;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  border-right: 1px solid rgba(255, 173, 66, 0.3);
}
.alfi2:last-child,.alfi:last-child{
  border-right: none;
}
.cloud_video{
  width: 75%;
}
.cloud_info{
  width: 25%;
  padding-left: 30px;
  box-sizing: border-box;
  color: #fff;
}
.ud_t {
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 700;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}
.ud_c {
  font-size: 16px;
  font-family: 'PingFang SC';
  /* font-weight: bold; */
  line-height: 23px;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 10px;
}
</style>
